* {
  padding: 0;
  margin: 0;
  box-sizing: border-box
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: gellatio;
  src: url("../src/assets/fonts/gellatio.ttf");
}


/* -----------------TEXT/FONT SIZING SECTION START-------------------- */
.lead {
  font-size: 1.25rem;
  font-weight: 300;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 2.2rem;
}

.text-center {
  text-align: center !important;
}

.gellatio-font {
  font-family: gellatio;
  font-size: 50px !important;
  padding-top: 20px;
}


/* --text sizing--  */
h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.textThroughout {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 175%;
  margin: 20px;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
/* -----------------TEXT/FONT SIZING SECTION END-------------------- */










/* -----------------HERO SECTION START-------------------- */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover !important;
  background: rgb(130, 129, 129);
  background: linear-gradient(90deg, rgba(130, 129, 129, 0.7) 0%, rgba(130, 129, 129, 0.7) 100%), url("../src/assets/images/HeroImage.jpg");
}

.Hero {
  font-size: 6.25rem;
  font-weight: 100;
  line-height: 17.5rem;
  position: absolute;
  font-family: gellatio, sans-serif;
  text-align: center;
}

/* -----------------HERO SECTION END-------------------- */














/* -----------------NAV BAR SECTION START-------------------- */

.navAppBar {
transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
/* box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px; */
display: flex;
width: 100%;
box-sizing: border-box;
flex-shrink: 0;
position: fixed;
z-index: 1100;
top: 0px;
left: auto;
right: 0px;
background-color: rgb(0 0 0 / 8%) !important;
color: rgb(255, 255, 255);
}

a:hover {
  color: #ebc1c3 !important;
}
/* nav icon image to the left */
.navIconDisplay {
    display: block !important;
    text-align: left !important;
}

.navButton {
display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.navFull {
  
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;
  background-color: transparent !important;
  color: #fff;
}

.navImage {
  height: 50px;
  width: 50px;
}

/* -----------------NAV BAR SECTION END-------------------- */








/* -----------------GROW SECTION START -------------------- */
.GrowIcon {
  height: 120px;
}

.GrowParagraphs {
  padding: 5px;
}

#Grow {
  background-color: #ebc1c3 !important;
  padding-bottom: 10%;
  padding-top: 7%;
  padding-right: 7%;
  padding-left: 7%;
}
/* -----------------GROW SECTION END -------------------- */














/* -----------------MEETME  SECTION START-------------------- */
#MeetMe {
  background-color: #d5c1f5;
  min-height: 100vh;
  background-image: url(../src/assets/images/BodyBack.webp);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 10%;
  padding-bottom: 5% !important;
}

.MeetMeBack {
  background-color: #d5c1f5;
  
  border-radius: 16px;
}

.ChristinaPicture {
  height: 50px;
  width: 50px;
  height: 250px;
  width: 250px;
  position: absolute;
  transform: translateY(-100%);
  left: 43%;
  z-index: 0;
  opacity: 0.5;
  border-radius: 50%;
  padding: 8px;
  
}

.MeetMeParagraphs {
  padding: 20px;
}


/* -----------------MEETME  SECTION END-------------------- */












/* -----------------OFFERING SECTION START -------------------- */
#Offerings {
  background-color: #ebc1c3 !important;
  padding-bottom: 10%;
  background-image: url(../src/assets/images/OfferingBackImage.png);
}

.OfferingCard {
  background-color: #d5c1f5;
  background-color: #d5c1f5 !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
  border-radius: 16px;
  margin-top: 5%;
}

.OfferingDescriptions {
  white-space: pre-wrap;
}


/* Offering Card Container End */

.OfferingIcon {
  height: 200px;
  padding-top: 30px;
}

.ScheduleButton {
  width: 45px;
  padding-top: 3%;
}

.OfferingATag {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87)
}

.OfferName {
  padding: 20px;
}

.OfferPrice {
  padding-bottom: 15px;
}

.OfferDescription {
  padding-top: 15px;
}

.OfferSchedule {
  padding-bottom: 3%;
}

.homecomingGrid {
 padding-left: 30px;
 padding-right: 30px;
}

.homecomingCenterText {
  font-size: .8rem;
  font-weight: 300;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5rem;
}

.offeringItem {
  background-color: #d5c1f5 !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: #fff;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  padding: 8px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
/* -----------------OFFERING SECTION END -------------------- */












/* -----------------REVIEWS SECTION START -------------------- */
#Reviews {
  background-color: #d5c1f5;
  min-height: 100vh;
  background-image: url(../src/assets/images/BodyBack.webp);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 50vh;
  padding-bottom: 25px;
  padding-top: 25px;
  
}

.ReviewsBack {
  background-color: #d5c1f5;
  
  
}

.ReviewNames {
  font-size: 1.00rem;
  font-weight: 300;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.reviewContainer {
  background-color: #d5c1f5;
  vertical-align: middle;
  padding-bottom: 20px !important;
  padding-top: 20px !important;
  border-radius: 18px;
  background-image:   url('../src/assets/images/ReviewsImage.png');
  background-repeat: no-repeat;
  background-size: 200px;
  background-position: 75% 25%;
}

.css-1oqqzyl-MuiContainer-root {
  padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
 
}

.starRating {
  background-color: #d5c1f5;
}






/* -----------------REVIEWS SECTION END -------------------- */














/* -----------------CONTACT SECTION START -------------------- */

#Contact {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  min-height: 100vh;
  background: linear-gradient(90deg, rgba(130, 129, 129, 0.7) 0%, rgba(130, 129, 129, 0.7) 100%), url("../src/assets/images/ContactBack.webp");
  padding-top: 35px !important;
}

.contactFormContainer {
 

  margin: auto;
  width: 50%;
  height: 75%;
  border: 3px;
  padding: 10px;
  margin-bottom: 33px;
  padding-top: 33%;
  margin-top: 50px;
  position: relative;
}

.contactFormForm{
 
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  height: 450px;
  width: 400px;
  transform: translate(-50%, -50%);
  background: linear-gradient(#ededed70, #ede6f5);
  border-radius: 12px !important;
}

.contactInputs {
  border:#282c34;
 
 
  
 
}

input {
  margin: 3% !important;
  border-radius: 8px !important;
  height: 30px !important;
  border: none;
}

textarea {
  margin: 10%;
  border-radius: 8px !important;
  height: 45px !important;
  width: 200px !important;
  border: none;
}

.contactSubmitButton {
  height: 40px !important;
  width: 33%;
  background-color: #d5c1f5;
  color: white;
  border-radius: 8px !important;
  border-color: block !important;
  border: none;
}





/* -----------------CONTACT SECTION END -------------------- */














/* -----------------FOOTER SECTION START -------------------- */

footer {
  background-color: #ebc1c3 !important;
  min-height: 15vh;
}

.footerText {
  font-family: gellatio;
  font-size: 25px !important;
  padding-top: 20px;
}

.socialLinks {
  padding: 10px;
  
}



/* -----------------FOOTER SECTION END -------------------- */















/* -----------------FAQSECTION START -------------------- */

#FAQ {
  background-color: #ebc1c3 !important;
  min-height: 15vh;
}

.faqQuestions {
  font-family: gellatio;
  font-size: 25px !important;
  padding-top: 20px;
}

.FAQIcon {
  height: 80px;
  width: 80px;
}

.FAQBody {
  background-color: #ebc1c3;
}

/* -----------------FAQ SECTION END -------------------- */





/* --------------------------- Media Screen Sizing ---------------------- */

@media only screen and (max-width: 600px) {

  .css-wf16b5 {
    background-color: transparent !important;
    box-shadow: none !important;
  
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !important;

}


  .Hero {
    font-size: 2.00rem;
    line-height: 3rem;

  }

  
  .GrowIcon {
    height: 80px;
}

  .ChristinaPicture {
    height: 100px;
    width: 100px;
    position: absolute;
    transform: translateY(-100%);
    left: 37%;
    z-index: 0;
    opacity: 0.5;
    border-radius: 50%;
    padding: 8px;
}

.gellatio-font {
  font-family: gellatio;
  font-size: 30px !important;
  padding-top: 20px;
}

.contactFormForm {
  position: absolute;
  top: 100%;
  left: 50%;
  height: 400px !important;
  width: 300px;
  transform: translate(-50%, -50%);
  background: linear-gradient(#ededed70, #ede6f5);
  border-radius: 12px !important;
  margin-top: 5% !important;
}

.contactInputs {
  border: #282c34;
 
}

#Contact {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  min-height: 70vh;
  background: linear-gradient(90deg, rgba(130, 129, 129, 0.7) 0%, rgba(130, 129, 129, 0.7) 100%), url("../src/assets/images/ContactBack.webp");
  padding-top: 15px !important;
}

.contactFormContainer {
 

  margin: auto;
  width: 50%;
  height: 75%;
  border: 3px;
  padding: 10px;
  padding-top: 33%;
  margin-top: 50px;
  position: relative;
}


/* review container */
.css-1oqqzyl-MuiContainer-root {
  width: 94%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
}



/* nav bar  */
.navFull {
  background-color: transparent !important;
  color: #f8f9fa !important;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  z-index: 1200;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  
  height: 50px !important;
  width: 100% !important;
  outline: 0;
  left: 0;
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: transparent !important;
  color: white !important;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  z-index: 1200;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  outline: 0;
  left: 0;
  width: 100% !important;
  height: 10% !important;
}



.navImage {
  display: none;
}

.mobileNavDrawer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 50% !important;
  width: 100% !important;
  background-color: transparent !important;
  flex: 1 0 auto;
  z-index: 1200;
  position: fixed;
  top: 0px;
  outline: 0px;
  left: 0px;
  color: white !important;
}

.mobileDrawer {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.87);
  /* transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  box-shadow: none !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 0% !important;
  flex: 1 0 auto;
  z-index: 1200;
  position: fixed;
  top: 0px;
  outline: 0px;
  left: 0px;
}

/* homecoming offerings bottom */
.OfferingIcon {
  background-color: #d5c1f5;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: .5rem !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  padding: 8px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}


.faqAppBar {
  background-color: transparent !important;
  color: white;
  align-items: center;
}


.offeringItem {
  background-color: #d5c1f5 !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: #fff;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 300 !important;
  font-size: 0.5rem !important;
  line-height: 1 !important;
  letter-spacing: 0.01071em;
  padding: 8px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

span.navButton{
  font-size: 1rem;
}
}
